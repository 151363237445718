export default {
  init () {
    // JavaScript to be fired on all pages

    const pageBody = document.body
    const header = document.getElementById('header')

    let scrollPos

    document.addEventListener('scroll', e => {
      if (!pageBody.classList.contains('fixed')) {
        scrollPos = window.scrollY
      }

      let ticking = false
      if (!ticking) {
        setTimeout(function () {
          if (scrollPos > 0) {
            header.classList.add('scrolled')
          } else {
            header.classList.remove('scrolled')
          }
          ticking = false
        }, 16)
        ticking = true
      }
    })

    const nav = () => {
      const mainNav = document.querySelector('.header__nav'),
        mobileNavToggle = document.querySelector('.nav-toggle'),
        subnavToggle = document.querySelectorAll('.subnav-toggle'),
        subnav = document.querySelectorAll('.nav-subnav'),
        subnavLinks = document.querySelectorAll('.subnav-item a')

      let intViewportWidth = window.innerWidth

      // reset nav for desktop/mobile
      const resetNav = () => {
        subnavToggle.forEach(toggle => {
          toggle.setAttribute('aria-pressed', 'false')
        })
        subnav.forEach(nav => {
          nav.setAttribute('aria-hidden', 'true')
          nav.style.height = 0
        })
        subnavLinks.forEach(link => {
          // prevent tabbing to hidden links
          link.setAttribute('tabindex', '-1')
        })
        pageBody.classList.remove('fixed')
        pageBody.removeAttribute('top')
        window.scrollTo(0, scrollPos)
      }

      // initial nav setup
      const setNav = () => {
        intViewportWidth = window.innerWidth
        if (intViewportWidth < 1024) {
          mobileNavToggle.setAttribute('aria-pressed', 'false')
          mainNav.setAttribute('aria-hidden', 'true')
        } else {
          mobileNavToggle.setAttribute('aria-pressed', 'false')
          mainNav.removeAttribute('aria-hidden')
        }
        resetNav()
      }

      // mobile nav toggle
      const toggleMainNav = () => {
        mobileNavToggle.addEventListener('click', e => {
          if (e.target.getAttribute('aria-pressed') === 'true') {
            // keep doc scroll position
            pageBody.classList.remove('fixed')
            pageBody.removeAttribute('top')
            window.scrollTo(0, scrollPos)
            // toggle menu
            e.target.setAttribute('aria-pressed', 'false')
            mainNav.setAttribute('aria-hidden', 'true')
          } else {
            // toggle menu
            e.target.setAttribute('aria-pressed', 'true')
            mainNav.setAttribute('aria-hidden', 'false')
            // keep page from scrolling/set current scroll pos
            setTimeout(() => {
              pageBody.classList.add('fixed')
              pageBody.setAttribute('top', scrollPos + 'px')
            }, 301)
          }
        })
      }

          // subnav toggle for desktop/mobile
        const toggleSubnav = () => {
          const toggler = target => {
            if (target.getAttribute('aria-pressed') === 'true') {
              resetNav()
            } else {
              resetNav()
              const itemSubnav = target.nextElementSibling,
                itemSubnavItem = itemSubnav.querySelectorAll('.subnav-item')
              let itemSubnavHeight = 0
              itemSubnavItem.forEach(link => {
                // get total subnav height
                itemSubnavHeight = itemSubnavHeight + link.offsetHeight
                // allow tabbing to visible links
                link.querySelector('a').toggleAttribute('tabindex')
              })
              target.setAttribute('aria-pressed', 'true')
              itemSubnav.setAttribute('aria-hidden', 'false')
              itemSubnav.style.height = itemSubnavHeight + 'px'

              // Close subnav menu when clicking outside
              document.addEventListener('click', closeSubnavOnClickOutside)
            }
          }

          const closeSubnavOnClickOutside = event => {
            const subnavMenus = document.querySelectorAll('.subnav-menu')
            const isClickInsideSubnav = Array.from(subnavMenus).some(subnavMenu =>
              subnavMenu.contains(event.target)
            )
            if (!isClickInsideSubnav) {
              resetNav()
              document.removeEventListener('click', closeSubnavOnClickOutside)
            }
            event.stopPropagation() // Prevent event from propagating further
          }

          subnavToggle.forEach(toggle => {
            toggle.addEventListener('click', e => {
              e.preventDefault()
              toggler(e.target)
              e.stopPropagation() // Prevent event from propagating further
            })
          })
        }


      window.onload = setNav
      window.onresize = setNav
      toggleMainNav()
      toggleSubnav()
    }

    nav()

    // intersection observer
    const setInView = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('in-view')
        }
      })
    }

    const itemOptions = {
      threshold: 0.5
    }

    const itemObserver = new IntersectionObserver(setInView, itemOptions)

    const items = document.querySelectorAll('.animate')
    items.forEach(item => {
      itemObserver.observe(item)
    })

    // stories filtering
    const filterDropdown = document.querySelector('.stories__filter')

    if (filterDropdown) {
      const dropdownHeaders = document.querySelectorAll('.dropdown-header')

      dropdownHeaders.forEach(header => {
        const dropdownMenu = header.nextElementSibling
        const defaultText = header.textContent

        // Set default placeholder text
        header.dataset.placeholder = defaultText

        header.addEventListener('click', function () {
          const filterField = this.parentElement

          // Close other dropdowns
          const allFilterFields = document.querySelectorAll('.custom-dropdown')
          allFilterFields.forEach(field => {
            if (field !== filterField && field.classList.contains('active')) {
              field.classList.remove('active')
              field.querySelector('.dropdown-options').classList.remove('open')
            }
          })

          filterField.classList.toggle('active')
          dropdownMenu.classList.toggle('open')
        })
      })

      // Close dropdown when clicking outside the box
      document.addEventListener('click', function (event) {
        const dropdowns = document.querySelectorAll('.custom-dropdown')
        dropdowns.forEach(dropdown => {
          if (!dropdown.contains(event.target)) {
            dropdown.classList.remove('active')
            dropdown.querySelector('.dropdown-options').classList.remove('open')
          }
        })
      })

      const dropdownOptions = document.querySelectorAll('.dropdown-options li')

      dropdownOptions.forEach(option => {
        option.addEventListener('click', function () {
          const dropdownMenu = this.parentElement
          const dropdownHeader = dropdownMenu.previousElementSibling
          const value = this.dataset.value

          const filterField = dropdownHeader.parentElement
          const currentValue = filterField.dataset.value
          let currentValues = currentValue ? currentValue.split(',') : []

          if (currentValues.includes(value)) {
            // Deselect the option
            const index = currentValues.indexOf(value)
            if (index !== -1) {
              currentValues.splice(index, 1)
            }
          } else {
            // Select the option
            currentValues.push(value)
          }

          filterField.dataset.value = currentValues.length
            ? currentValues.join(',')
            : ''

          if (currentValues.length > 0) {
            if (currentValues.length === 1) {
              dropdownHeader.textContent = currentValues[0]
            } else {
              dropdownHeader.textContent = currentValues.join(', ')
            }
          } else {
            // If no options are selected, display placeholder text
            const defaultText =
              dropdownHeader.dataset.placeholder || 'Select an option'
            dropdownHeader.textContent = defaultText
          }

          // Toggle current class for styling
          this.classList.toggle('current')

          // Call filterContent with the current values
          filterContent(currentValues)
        })
      })

      function filterContent (values) {
        // Implement your filtering logic here
        // For example, you can filter content based on the selected values
        console.log('Filtering with values:', values)
      }
    }

  // load more stories
const loadMore = document.getElementById('loadMoreBtn')

if (loadMore) {
  // Function to load more stories
  function loadMoreStories () {
    const storyItems = document.querySelectorAll('.stories__list__item.hidden')
    const numToDisplay = 12
    let numDisplayed = 0

    // Loop through hidden story items
    storyItems.forEach((item, index) => {
      // Check if within the range to display
      if (numDisplayed < numToDisplay) {
        item.classList.remove('hidden')
        numDisplayed++
      }
    })

    // Hide the load more button if all stories are displayed
    if (document.querySelectorAll('.stories__list__item.hidden').length === 0) {
      document.getElementById('loadMoreBtn').classList.add('disabled')
    }
  }

  loadMore.addEventListener('click', loadMoreStories)

  // Initially hide all story items except the first 12
  document.querySelectorAll('.stories__list__item').forEach((item, index) => {
    if (index >= 12) {
      item.classList.add('hidden')
    }
  })
}

  },

  finalize () {
    // JavaScript to be fired on all pages, after page specific JS is fired
  }
}
